import './Products.scss';
import package1 from '../../assets/products/package-1.png';
import package2 from '../../assets/products/package-2.png';
import package3 from '../../assets/products/package-3.png';
import {Box, Heading, List, ListIcon, ListItem, Text} from "@chakra-ui/react";
import {CheckIcon} from "@chakra-ui/icons";

import GermanPackages from '../../lang/de/products.json';
import DutchPackages from '../../lang/nl/products.json';
import FrenchPackages from '../../lang/fr/products.json';
import EnglishPackages from '../../lang/en/products.json';
import SwissPackages from '../../lang/ch/products.json';
import SpanishPackages from '../../lang/es/products.json';
import PolishPackages from '../../lang/pl/products.json';
import IrishPackages from '../../lang/ie/products.json';
import {FormattedMessage} from "react-intl";

function Products(props) {
    const images = [];
    images['package1'] = package1;
    images['package2'] = package2;
    images['package3'] = package3;

    const whichPackage = () => {
        switch (true) {
            case props.totalDays >= 1 && props.totalDays <= 29:
                return 'package1';
            case props.totalDays >= 30 && props.totalDays <= 44:
                return 'package2';
            case props.totalDays > 44:
                return 'package3';
            default:
                return 'package3';
        }
    };

    const getImage = (product) => {
        return images[product];
    };

    let packages = [];

    switch (props.country) {
        case 'nl':
            packages = DutchPackages;
            break;
        case 'fr':
            packages = FrenchPackages;
            break;
        case 'en':
            packages = EnglishPackages;
            break;
        case 'en-ie':
            packages = IrishPackages;
            break;
        case 'ch':
            packages = SwissPackages;
            break;
        case 'es':
            packages = SpanishPackages;
            break;
        case 'pl':
            packages = PolishPackages;
            break;
        default:
            packages = GermanPackages;
    }

    let product = packages[whichPackage()];

    if (props.splitVersion) {
        product = packages['splitTest'][props.splitVersion][whichPackage()];
    }

    return (
        <Box className="packages-container" textAlign={'center'}>
            <Heading as="h3" size="xl">{product.heading}</Heading>

            <img className={'product-image'} src={getImage(whichPackage())} alt=""/>

            <Heading as="h4" size="md" mb={3}>{product.subheading}</Heading>

            <List spacing={3}>
                <ListItem>
                    <ListIcon mb={0.5} as={CheckIcon} color='white'/>
                    <FormattedMessage id="product.fact1"/>
                </ListItem>
                <ListItem>
                    <ListIcon mb={0.5} as={CheckIcon} color='white'/>
                    <FormattedMessage id="product.fact2"/>
                </ListItem>
                <ListItem>
                    <ListIcon mb={0.5} as={CheckIcon} color='white'/>
                    <FormattedMessage id="product.fact3"/>
                </ListItem>
                <ListItem>
                    <ListIcon mb={0.5} as={CheckIcon} color='white'/>
                    <FormattedMessage id="product.fact4"/>
                </ListItem>
            </List>

            <Box my={6}>
                <Heading as="h3" size="xl">{product.price}</Heading>
                <Text fontSize="14">
                    <FormattedMessage id="product.bottlePrice"/>
                </Text>
            </Box>

            <Text>{product.totalPrice}</Text>
        </Box>
    )
}

export default Products;
